import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { DirectivesModule } from './directives/directives.module';
@NgModule({
    declarations: [
    ],
    imports: [
        FormsModule,
        RouterLink,
        ReactiveFormsModule,
        CustomLoaderComponent,
        TranslocoModule,
        CommonModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        TranslocoModule,
        CommonModule,
        DirectivesModule
    ]
})
export class SharedModule {
}
