import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticatedDirective } from './authenticated-directive/authenticated-directive.directive';



@NgModule({
  declarations: [
    AuthenticatedDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AuthenticatedDirective
  ]
})
export class DirectivesModule { }
