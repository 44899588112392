import { isPlatformBrowser } from '@angular/common';
import { Directive, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthentication } from 'app/store/reducers';
import { AuthenticationState } from 'app/store/states/authentication.state';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[appAuthenticatedDirective]',
  exportAs : 'appAuthenticatedDirective',
})
export class AuthenticatedDirective implements OnInit,OnDestroy{

  @Input({required: true}) logged: boolean;
  private _store:Store = inject(Store)
  private _templateRef: TemplateRef<any> = inject(TemplateRef)
  private _viewContainerRef:ViewContainerRef = inject(ViewContainerRef)
  private _platfromId = inject(PLATFORM_ID)


  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    if(isPlatformBrowser(this._platfromId)){
      this._store.select(selectAuthentication).pipe(
        takeUntil(this._unsubscribeAll),
        tap((authState : AuthenticationState)=>{
          if(authState.isLoggedIn === this.logged){
            this._viewContainerRef.createEmbeddedView(this._templateRef);
          }else{
            this._viewContainerRef.clear();
          }
        })
      ).subscribe()
    }

  }

  private _unsubscribeAll : Subject<any> = new Subject();
  isLoggedIn = false;
}
